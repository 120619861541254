body
{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: linear-gradient(to left, #1a318e , #081444);
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
}

.debug_btn
{
    display: none;
}

h1
{
    color: #fff;
}

.err_link
{
    font-size: 80px;
}

.clear
{
    clear: both;
}

.nodata_msg
{
    color: #fff;
}

.load_btn
{
    background-color: #c6cce9;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px;
    cursor: pointer;
}

.inside_btn
{
    background-color: #c6cce9;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px;
    cursor: pointer;
}

.edit_btns .inside_btn
{
    margin-right: 10px;
}

.delete_btn
{
    background-color: #d40c0c;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px;
    cursor: pointer;
    color: #fff;
}

.pop_btn
{
    background-color: #c6cce9;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px;
    cursor: pointer;
}

.search_btn
{
    background-color: #c6cce9;
    margin: 0 !important;
    border: none;
    border-radius: 0 10px 10px 0;
    font-size: 12px;
    font-weight: bold;
    width: 70px;
    height: 30px;
    cursor: pointer;
    float: left;
}

.load_btn:hover, .inside_btn:hover, .pop_btn:hover, .search_btn:hover
{
    background-color: #a0a5be;
}

.delete_btn:hover
{
    background-color: #9e0b0b;
}

.call_btn
{
    background-color: #5672e6;
    color: #fff;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    padding: 7px 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.call_btn:hover
{
    background-color: #4c63be;
}

.vid_container
{
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
}

.vid 
{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
 }

 .vid iframe
{
    position:absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.logo_top
{
    width: calc(100% - 80px);
    margin-top: 20px;
    margin-left: 80px;
    text-align: center;
}

.logo_uni
{
    width: 15%;
    max-width: 200px;
    height: auto;
    float: left;
    margin-left: 20px;
}

.logo_ev
{
    width: 33%;
    max-width: 450px;
    height: auto;
    float: right;
    margin-right: 20px;
}

.nav_bar
{
    width: 80px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-top: 20px;
    height: calc(100vh - 20px);
    background-color: #fff;
    text-align: center;
    z-index: 100;
}

.nav_link
{
    display: block;
    text-decoration: none;
    color: #1a318e;
    margin: 0;
    margin-bottom: 30px;
    opacity: .4;
}

.nav_link:hover
{
    opacity: 1;
}

.active
{
    opacity: 1;
}

.nav_link img
{
    width: 20px;
}

.nav_link p
{
   margin: 0;
   font-weight: bold;
   font-size: 14px;
}

.decorative
{
    position: absolute;
    bottom: 0;
}

.decorative img
{
    width: 100%;
}

.main
{
    width: calc(100% - 80px);
    margin-left: 80px;
    margin-top: 50px;
    text-align: center;
    padding-bottom: 80px;
}

.main2
{
    width: calc(100% - 80px);
    height: calc(100vh - 250px);
    margin-left: 80px;
    margin-top: 50px;
    text-align: center;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_boxes
{
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
}

.search_box
{
    margin: 0 auto;
    margin-bottom: 10px;
    width: 60%;
}

.search_box input
{
    width: calc(96% - 70px);
    padding: 0 2%;
    border: none;
    height: 30px;
    float: left;
}

.question_box
{
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
}

.question_txt
{
    width: 92%;
    padding: 4%; 
}

.question_mt
{
    font-size: 25px;
    color: #081444;
    font-weight: bold;
}

.question_st
{
    font-size: 16px;
    color: #1a318e;
    margin: 0;
}

.question_at
{
    font-size: 14px;
    color: gray;
    margin: 0;
}

.question_likes
{
    width: calc(100% - 40px);
    padding: 10px 20px;
    background-color: #ededed;
    border-bottom: 1px solid #e2e2e2;
}

.question_btn
{
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2%;
    cursor: pointer;
}

.question_btn:hover
{
    opacity: .8;
}

.question_btn img
{
    width: 30px;
}

.question_btn p
{
    margin: 0;
    margin-left: 3px;
    font-size: 13px;
    font-weight: bold;
}

.question_ok
{
    float: right;
}

.question_stat
{
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}

.msg_ready
{
    font-size: 14px !important;
    margin-top: 10px !important;
    color: #039e31;
    display: none;
}

.question_res
{
    text-align: left;
    padding: 20px;
    background-color: #ededed;
}

.question_res p
{
    margin: 0;
    font-size: 16px;
}

.res_tt
{
    font-weight: bold;
    margin-bottom: 5px !important;
}

.question_res textarea
{
    width: calc(100% - 20px);
    padding: 10px;
    max-width: calc(100% - 20px);
    display: block;
}

.msg_box
{
    background-color: #fff;
    padding: 4%;
    width: 72%;
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
}

.msg_field
{
    width: 100%;
    margin-bottom: 2%;
}

.msg_field label
{
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.msg_field input
{
    width: calc(96% - 4px);
    display: block;
    padding: 1% 2%;
    margin: 0;
}

.popup
{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, .7);
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 200;
}

.pop_box
{
    width: 60%;
    background-color: #fff;
    text-align: center;
    padding: 4%;
}

.pop_box p
{
    margin: 0;
    margin-bottom: 3%;
}

.pop_box h3
{
    margin: 0;
    margin-bottom: 2%;
}

/*****************RESPONSIVE*************************/
@media only screen and (max-width: 800px) {


    .nav_bar
    {
        bottom: 0;
        width: 100%;
        height: 60px;
        padding: 5px 0;
    }

    .decorative
    {
        display: none;
    }

    .nav_link
    {
        display: inline-block;
        margin: 0 20px;
        margin-top: 5px;
    }

    .logo_top
    {
        width: 100%;
        text-align: center;
        margin-left: 0px;
    }

    .logo_uni
    {
        width: 20%;
    }

    .logo_ev
    {
        width: 43%;

    }
    
    .main
    {
        width: 100%;
        height: auto;
        margin-left: 0;
    }

    .main2
    {
        width: 100%;
        margin-left: 0;
    }

    .search_box
    {
        width: 100%;
    }

}